import React from "react";

const Footer = () => {
  var d = new Date();
  return (
    <div className="footer position-fixed start-0 end-0 bottom-0 mt-0" style={{zIndex:1}}>
      <div className="copyright bg-white mt-0 py-2">
        <p>Copyright © {d.getFullYear()} Bizlib. All rights reserved.</p>
      </div>
    </div>
  );
};

export default Footer;
